import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBlYEx7YcXAoLnBDr4Em3zHLXPXgZ47wtY",
  authDomain: "attoli-prod.firebaseapp.com",
  projectId: "attoli-prod",
  storageBucket: "attoli-prod.appspot.com",
  messagingSenderId: "864307203733",
  appId: "1:864307203733:web:25eea8a956900bc47dd59c",
  measurementId: "G-8LKH4CWVT6",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth, firebaseConfig };
