import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./Navbar.css";
import attoliHome from "../assets/attoli-home.png";

const Navbar = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [user, setUser] = useState(null);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      lastScrollY = window.scrollY;
    };

    const handleMouseMove = (e) => {
      if (e.clientY < 50) {
        setShowHeader(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      unsubscribe();
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const toggleAccountMenu = () => {
    setShowAccountMenu(!showAccountMenu);
  };
  const scrollToAbout = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const aboutSection = document.querySelector(".about");
        aboutSection?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      const aboutSection = document.querySelector(".about");
      aboutSection?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToPricing = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const aboutSection = document.querySelector(".product");
        aboutSection?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      const aboutSection = document.querySelector(".product");
      aboutSection?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="navbar">
      <input type="checkbox" id="sidebar-active" />
      <label htmlFor="sidebar-active" className="open-sidebar-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          viewBox="0 -960 960 960"
          width="32px"
          fill="#000000"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      </label>
      <label id="overlay" htmlFor="sidebar-active"></label>
      <div className="links-container">
        <label htmlFor="sidebar-active" className="close-sidebar-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32px"
            viewBox="0 -960 960 960"
            width="32px"
            fill="#000000"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </label>
        <a href="/" className="home-link nav-link">
          <img src={attoliHome} alt="Attoli Home" className="logo" />
        </a>
        <a
          href="/"
          className="nav-link"
          onClick={(e) => {
            e.preventDefault();
            scrollToAbout();
          }}
        >
          {" "}
          About Us
        </a>
        {user && (
          <a href="/sum-tool" className="nav-link">
            {" "}
            Sumtool{" "}
          </a>
        )}
        {!user && (
          <a
            href="/"
            className="nav-link"
            onClick={(e) => {
              e.preventDefault();
              scrollToPricing();
            }}
          >
            {" "}
            Pricing{" "}
          </a>
        )}
        <a href="/how-to" className="nav-link">
          {" "}
          How To{" "}
        </a>
        <a href="/faq" className="nav-link">
          {" "}
          FAQ{" "}
        </a>
        {user && (
          <div className="account-container">
            <div className="account-icon" onClick={toggleAccountMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28px"
                viewBox="0 0 24 24"
                width="30px"
                fill="#f0f0f0"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
              </svg>
            </div>
            {showAccountMenu && (
              <div className="account-menu">
                <a href="/account" className="nav-link">
                  Account
                </a>
                <a href="/" onClick={handleLogout} className="nav-link">
                  Logout
                </a>
              </div>
            )}
          </div>
        )}
        {user && (
          <div className="less-account-container">
            <a href="/account" className="nav-link">
              Account
            </a>
            <a href="/" onClick={handleLogout} className="nav-link">
              Logout
            </a>
          </div>
        )}
        {!user && (
          <Link to="/login" className="nav-link">
            Login
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
