import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import FAQPage from "./components/FAQPage";
import TermsAndServicesPage from "./components/ToS";
import PrivacyAndLegalPage from "./components/PrivacyLegal";
import SumTool from "./components/SumTool";
import LoginPage from "./components/LoginPage";
import SignUp from "./components/SignUp";
import AccountPage from "./components/AccountPage";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Verify from "./components/Verify";
import Success from "./components/Success";
import HowTo from "./components/howtopage";
import Plans from "./components/Plans";

function AppContent() {
  const [showHeader, setShowHeader] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      lastScrollY = window.scrollY;
    };

    const handleMouseMove = (e) => {
      if (e.clientY < 50) {
        setShowHeader(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      unsubscribe();
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);


  return (
    <div className="app-container">
      <Navbar />
      <div className="container"> 
       <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/sum-tool" element={<SumTool />} />
          <Route path="/terms" element={<TermsAndServicesPage />} />
          <Route path="/privacy" element={<PrivacyAndLegalPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/success" element={<Success />} />
          <Route path="/how-to" element={<HowTo />} />
          <Route path="/plans" element={<Plans />} />
        </Routes>
      </div>
      <footer className="footer">
        <p>© 2024 attoli - All Rights Reserved</p>
        <div className="footer-links">
          <Link to="/terms">Terms</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
      </footer>
    </div>
  );
}

export default AppContent;
