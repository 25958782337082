import React, { useState, useEffect } from "react";
import { onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import {
  KeyRound,
  CreditCard,
  ShoppingCart,
  LogOut,
  Loader,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./AccountPage.css";

const AccountPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [alertVisible, setAlertVisible] = useState(true);
  const [alertOpacity, setAlertOpacity] = useState(1);
  const [successMessage, setSuccessMessage] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (currentUser.emailVerified) {
          setUser(currentUser);
          setIsVerified(currentUser.emailVerified);
          setLoading(false);
        } else {
          navigate("/verify");
        }
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleOpenBillingPortal = async () => {
    setBillingLoading(true);
    try {
      console.log("Creating billing portal session...");
      const functions = getFunctions();
      const createBillingPortalSession = httpsCallable(
        functions,
        "createBillingPortalSession"
      );
      const result = await createBillingPortalSession();
      window.location.href = result.data.url;
    } catch (error) {
      console.error("Error details:", error);
      setBillingLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (user && user.email) {
      try {
        await sendPasswordResetEmail(auth, user.email);
        setSuccessMessage(
          "Password reset email sent! If you don't see it please check your spam folder."
        );
        showAlert();
      } catch (error) {
        console.error("Error sending password reset email:", error);
        setError("Failed to send password reset email.");
        showAlert();
      }
    } else {
      alert("No user is logged in.");
    }
  };

  const handleCheckoutPlans = async () => {
    navigate("/plans");
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const showAlert = () => {
    setAlertVisible(true);
    setAlertOpacity(1);
  };

  const handleAlertClick = () => {
    setAlertOpacity(0);
    setTimeout(() => {
      setAlertVisible(false);
      setError(null);
      setSuccessMessage(null);
    }, 300);
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  return (
    <div className="account-page">
      {alertVisible && error && (
        <div
          className="alert alert-error"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {error}
        </div>
      )}
      {alertVisible && successMessage && (
        <div
          className="alert alert-info"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {successMessage}
        </div>
      )}
      <div className="account-card">
        <div className="account-header">
          <h1>Account Information</h1>
          <p>Welcome: {user.email}</p>
        </div>
        <div className="account-actions">
          <AccountButton
            icon={<KeyRound size={20} />}
            text="Change Password"
            onClick={handleChangePassword}
          />
          <AccountButton
            icon={<CreditCard size={20} />}
            text="Manage Billing"
            onClick={handleOpenBillingPortal}
            loading={billingLoading}
            disabled={billingLoading}
          />
          <AccountButton
            icon={<ShoppingCart size={20} />}
            text="Checkout Plans"
            onClick={handleCheckoutPlans}
            loading={checkoutLoading}
            disabled={checkoutLoading}
          />
          <AccountButton
            icon={<LogOut size={20} />}
            text="Logout"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

const AccountButton = ({ icon, text, onClick, loading, disabled }) => (
  <button className="account-button" onClick={onClick} disabled={disabled}>
    <span className="button-content">
      <span className="button-icon">
        {loading ? <Loader className="button-spinner" /> : icon}
      </span>
      <span className="button-text">{text}</span>
    </span>
    <span className="button-arrow">→</span>
  </button>
);

export default AccountPage;
