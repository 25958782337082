import React, { useState } from "react";
import { sendEmailVerification } from "firebase/auth";
import { MailWarning } from "lucide-react";
import { auth } from "../firebase";
import "./Verify.css";

const Verify = () => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [alertOpacity, setAlertOpacity] = useState(1);
  const [error, setError] = useState(null);

  const handleResend = () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          console.log("Verification email sent.");
          setSuccessMessage(
            "Email verification sent! If If you don't see it, please check your spam folder."
          );
          showAlert();
        })
        .catch((error) => {
          console.error("Error sending verification email:", error);
          setError("Unable to send verification email. Please try again later");
        });
    }
  };

  const showAlert = () => {
    setAlertVisible(true);
    setAlertOpacity(1);
  };

  const handleAlertClick = () => {
    setAlertOpacity(0);
    setTimeout(() => {
      setAlertVisible(false);
      setError(null);
      setSuccessMessage(null);
    }, 300);
  };

  return (
    <div className="verify-container">
      {alertVisible && error && (
        <div
          className="alert alert-error"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {error}
        </div>
      )}
      {alertVisible && successMessage && (
        <div
          className="alert alert-info"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {successMessage}
        </div>
      )}
      <div className="verify-card">
        <h1 className="verify-title">Verify Your Email</h1>
        <p className="verify-description">
          We've sent a verification link to your email address
        </p>
        <div className="verify-content">
          <MailWarning className="verify-icon" />
          <p>
            Please check your email and click on the verification link to
            activate your account. You won't be able to use all features of our
            website until your email is verified.
          </p>
          <p className="verify-small-text">
            If you don't see the email, please check your spam folder.
          </p>
        </div>
        <button className="verify-button" onClick={handleResend}>
          Resend Verification Email
        </button>
      </div>
    </div>
  );
};

export default Verify;
