import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import AppContent from "./AppContent";
import { firebaseConfig } from "./firebase";
import "./styles.css";

function App() {
  return (
    <Router>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <AppContent />
      </FirebaseAppProvider>
    </Router>
  );
}

export default App;
