import React, { useEffect } from "react";
import "./ToSstyles.css";

const ToS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tos-page-wrapper">
      <h1>Terms and Services</h1>
      <p>Last updated October 07, 2024. </p>

      <section>
        <h2>Agreement to Our Legal Terms</h2>
        <p>
          We operate the website https://attoli.com (the "Site"), as well as any
          other related products and services that refer or link to these legal
          terms (the "Legal Terms") (collectively, the "Services").
        </p>
        <p>
          Attoli provides a web extension to collect, consolidate, and expedite
          the information process.
        </p>
        <p>You can contact us by email at Attoli@outlook.com</p>
      </section>

      <section>
        <h2>1. Our Services</h2>
        <p>
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country.
        </p>
        <p>
          The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use the Services. You may not use the Services in a way that would
          violate the Gramm-Leach-Bliley Act (GLBA).
        </p>

        <p>This Service is intended solely for use by individuals residing in North America.
          By accessing or using our Service, you represent and warrant that you are not located in the European Economic Area (EEA)
          or any other jurisdiction where our data practices are regulated under the GDPR or other similar privacy laws.
          If you are a resident of the EEA, you are prohibited from creating an account or otherwise using this Service. 
          In the event that we become aware that a user is an EEA resident, we reserve the right to terminate their access
          to the Service and delete their account in compliance with our data policies.</p>
      </section>

      <section>
        <h2>2. Intellectual Property Rights</h2>
        <h3>Our intellectual property</h3>
        <p>
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the "Content"), as well as the
          trademarks, service marks, and logos contained therein (the "Marks").
        </p>
        <h3>Your use of our Services</h3>
        <p>
          Subject to your compliance with these Legal Terms, including the
          "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive,
          non-transferable, revocable license to: access the Services; and
          download or print a copy of any portion of the Content to which you
          have properly gained access solely for your personal, non-commercial
          use.
        </p>
      </section>

      <section>
        <h2>3. User Representations</h2>
        <p>
          By using the Services, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Legal
          Terms; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Services through automated or
          non-human means, whether through a bot, script or otherwise; (6) you
          will not use the Services for any illegal or unauthorized purpose; and
          (7) your use of the Services will not violate any applicable law or
          regulation.
        </p>
      </section>

      <section>
        <h2>4. User Registration</h2>
        <p>
          You may be required to register to use the Services. You agree to keep
          your password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
      </section>

      <section>
        <h2>5. Purchases and Payment</h2>
        <p>We accept the following forms of payment:</p>
        <ul>
          <li>Visa</li>
          <li>Mastercard</li>
          <li>American Express</li>
        </ul>
        <p>
          You agree to provide current, complete, and accurate purchase and
          account information for all purchases made via the Services. You
          further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed.
        </p>
      </section>

      <section>
        <h2>6. Subscriptions</h2>
        <h3>Billing and Renewal</h3>
        <p>
          Your subscription will continue and automatically renew unless
          canceled. You consent to our charging your payment method on a
          recurring basis without requiring your prior approval for each
          recurring charge, until such time as you cancel the applicable order.
        </p>
        <h3>Free Trial</h3>
        <p>
          We offer a free trial to new users who register with the Services. The
          account will not be charged and the subscription will be suspended
          until upgraded to a paid version.
        </p>
      </section>

      <section>
        <h2>7. Software</h2>
        <p>
          We may include software for use in connection with our Services. If
          such software is accompanied by an end user license agreement
          ("EULA"), the terms of the EULA will govern your use of the software.
          If such software is not accompanied by a EULA, then we grant to you a
          non-exclusive, revocable, personal, and non-transferable license to
          use such software solely in connection with our services and in
          accordance with these Legal Terms.
        </p>
      </section>

      <section>
        <h2>8. Prohibited Activities</h2>
        <p>
          You may not access or use the Services for any purpose other than that
          for which we make the Services available. The Services may not be used
          in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <p>As a user of the Services, you agree not to:</p>
        <ul>
          <li>
            Systematically retrieve data or other content from the Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Services.
          </li>
        </ul>
      </section>

      <section>
        <h2>9. User Generated Contributions</h2>
        <p>
          The Services may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Services, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions").
        </p>
      </section>

      <section>
        <h2>10. Contribution License</h2>
        <p>
          By posting your Contributions to any part of the Services, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use, copy, reproduce, disclose,
          sell, resell, publish, broadcast, retitle, archive, store, cache,
          publicly perform, publicly display, reformat, translate, transmit,
          excerpt (in whole or in part), and distribute such Contributions
          (including, without limitation, your image and voice) for any purpose,
          commercial, advertising, or otherwise, and to prepare derivative works
          of, or incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing.
        </p>
      </section>

      <section>
        <h2>11. Third-Party Websites and Content</h2>
        <p>
          The Services may contain (or you may be sent via the Site) links to
          other websites ("Third-Party Websites") as well as articles,
          photographs, text, graphics, pictures, designs, music, sound, video,
          information, applications, software, and other content or items
          belonging to or originating from third parties ("Third-Party
          Content").
        </p>
      </section>

      <section>
        <h2>12. Services Management</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the Services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the Services.
        </p>
      </section>

      <section>
        <h2>13. Privacy Policy</h2>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy: https://attoli.com/policy. By using the Services, you agree to
          be bound by our Privacy Policy, which is incorporated into these Legal
          Terms. Please be advised the Services are hosted in the United States.
        </p>
      </section>

      <section>
        <h2>14. Copyright Infringement</h2>
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately notify our
          Designated Copyright Agent using the contact information provided
          below (a "Notification").
        </p>
      </section>

      <section>
        <h2>15. Term and Termination</h2>
        <p>
          These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION.
        </p>
      </section>

      <section>
        <h2>16. Modifications and Interruptions</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services.
        </p>
      </section>

      <section>
        <h2>17. Governing Law</h2>
        <p>
          These Legal Terms shall be governed by and defined following the laws
          of Canada. Attoli and yourself irrevocably consent that the courts
          of Canada shall have exclusive jurisdiction to resolve any dispute
          which may arise in connection with these Legal Terms.
        </p>
      </section>

      <section>
        <h2>18. Dispute Resolution</h2>
        <h3>Informal Negotiations</h3>
        <p>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Legal Terms (each a "Dispute"
          and collectively, the "Disputes") brought by either you or us
          (individually, a "Party" and collectively, the "Parties"), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration.
        </p>
      </section>

      <section>
        <h2>19. Corrections</h2>
        <p>
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </p>
      </section>

      <section>
        <h2>20. Disclaimer</h2>
        <p>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF.
        </p>
      </section>

      <section>
        <h2>21. Limitations of Liability</h2>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES.
        </p>
      </section>

      <section>
        <h2>22. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys' fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Services; (3) breach of these Legal
          Terms; (4) any breach of your representations and warranties set forth
          in these Legal Terms; (5) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or
          (6) any overt harmful act toward any other user of the Services with
          whom you connected via the Services.
        </p>
      </section>

      <section>
        <h2>23. User Data</h2>
        <p>
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services.
        </p>
      </section>

      <section>
        <h2>24. Electronic Communications, Transactions, and Signatures</h2>
        <p>
          Visiting the Services, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing.
        </p>
      </section>

      <section>
        <h2>25. California Users and Residents</h2>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
      </section>

      <section>
        <h2>26. Miscellaneous</h2>
        <p>
          These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Legal Terms shall
          not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Legal Terms and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Legal
          Terms or use of the Services. You agree that these Legal Terms will
          not be construed against us by virtue of having drafted them. You
          hereby waive any and all defenses you may have based on the electronic
          form of these Legal Terms and the lack of signing by the parties
          hereto to execute these Legal Terms.
        </p>
      </section>

      <section>
        <h2>27. Contact Us</h2>
        <p>
          If you have any questions about these terms, please contact us at
          Attoli@outlook.com.
        </p>
      </section>
    </div>
  );
};

export default ToS;
