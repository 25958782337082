import React, { useState, useEffect } from "react";
import "./SumTool.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SumTool = () => {
  const [inputText, setInputText] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user.emailVerified) {
        if (user) {
          setUser(user);
          setIsVerified(user.emailVerified);
          setLoading(false);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/verify");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const countWords = (text) => {
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);

    if (!user) {
      setError("You must be logged in to use this feature.");
      setIsLoading(false);
      return;
    }

    try {
      const idToken = await user.getIdToken(true);
      const response = await fetch(
        "https://us-central1-attoli-prod.cloudfunctions.net/getChatGPTResponse",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            prompt: inputText,
            source: "highlight",
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.result) {
          setSummary(data.result);
        } else {
          setError("Unexpected response format");
        }
      } else {
        setError(data.error || "An error occurred while fetching the summary.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError("An error occurred while fetching the summary.");
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  return (
    <div className="page-wrapper">
      <h1 className="title">Summarization Tool</h1>
      <div className="tooltip-container">
        <a
          href="https://chromewebstore.google.com/detail/attoli/lndohbpmlohohecgflbkipepleefjila?authuser=0&hl=en/"
          target="_blank"
          rel="noopener noreferrer"
          className="chrome-extension-link"
        >
          Try our Chrome Extension
        </a>
        <span className="tooltip-text">
          We also offer a Chrome extension version. Click to check it out!
        </span>
      </div>
      <div className="input-area">
        <textarea
          className="textarea"
          placeholder="Paste your text/code here..."
          value={inputText}
          onChange={handleInputChange}
        />
        <div className="word-count">Word Count: {countWords(inputText)}</div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={isLoading || !inputText.trim() || !user}
        className="sum-button"
      >
        {isLoading ? "Summarizing..." : "Summarize"}
      </button>
      {error && <div className="error-message">{error}</div>}
      {summary && (
        <div className="summary-area">
          <h2 className="summary-title">Summary:</h2>
          <p className="summary-text">{summary}</p>
        </div>
      )}
    </div>
  );
};

export default SumTool;
