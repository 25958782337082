import React, { useRef, useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Zap, Shield, Star } from "lucide-react";
import "./LandingPagestyles.css";
import heroImage from "../assets/img(4).png";
import chrome from "../assets/chrome(2).png";
import about from "../assets/about-img(4).png";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="featureCard">
    <div className="iconWrapper">
      <Icon className="icon" />
    </div>
    <h3 className="title">{title}</h3>
    <p className="description">{description}</p>
  </div>
);
const TierCard = ({ title, features, buttonText, price, onButtonClick }) => (
  <div
    className="tierCard-container"
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = "translateY(-5px)";
      e.currentTarget.style.transitionDuration = "0.5s";
      e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.15)";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = "translateY(0)";
      e.currentTarget.style.transitionDuration = "0.5s";
      e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
    }}
  >
    <div
      style={{
        padding: "30px 20px",
        textAlign: "center",
      }}
    >
      {price && (
        <h2
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          {price}
        </h2>
      )}
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        {features.map((feature, index) => (
          <li
            key={index}
            style={{
              marginBottom: "10%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ color: "green", marginRight: "5px" }}>✓</div>{" "}
            {feature}
          </li>
        ))}
      </ul>
      <button
        onClick={() => onButtonClick(title)}
        style={{
          backgroundColor: "#15202f",
          color: "#e6f2ff",
          border: "none",
          padding: "10px 20px",
          borderRadius: "25px",
          fontWeight: "bold",
          cursor: "pointer",
          marginBottom: "15px",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#667589";
          e.currentTarget.style.color = "#e6f2ff";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#15202f";
          e.currentTarget.style.color = "#e6f2ff";
        }}
      >
        {buttonText}
      </button>
    </div>
  </div>
);

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const aboutRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const features = [
    {
      icon: Zap,
      title: "Fast Performance",
      description:
        "Effortlessly leverage the power of ChatGPT. attoli give you the ability to summarize information by summarizing the page or highlighting text and code!",
    },
    {
      icon: Shield,
      title: "Secure & Reliable",
      description:
        "Security handled by a trust and secure source! User management handled by Firebase. Payments handled by Stripe.",
    },
    {
      icon: Star,
      title: "User-Friendly",
      description:
        "attoli Chrome extension offers seamless integration without browser clutter while offering an intuitive way to summarize without leaving your browser tab.",
    },
  ];

  const tiers = [
    {
      title: "Basic",
      features: ["5 URL Summaries daiy", "Summarize up-to 3 page daily"],
      buttonText: "$0 USD/month",
      price: "Basic",
    },
    {
      title: "Member",
      features: [
        "50 URL Summaries daily",
        "Summarize up-to 12 pages daily",
        "Same great features but more!",
      ],
      buttonText: "$5 USD/month",
      price: "Member",
    },

    {
      title: "Superuser",
      features: [
        "Unlimited URL Summaries",
        "Summarize unlimited pages daily",
        "Same great features but even more!!!",
      ],
      buttonText: "$15 USD/month",
      price: "Superuser",
    },
  ];

  const handleGetStarted = () => {
    navigate("/login");
  };

  const handleSummTool = () => {
    navigate("/sum-tool");
  };

  const handleButtonClick = (tierTitle) => {
    if (tierTitle === "Basic") {
      navigate("/account");
    } else {
      navigate("/plans");
    }
  };

  const handleContact = () => {
    navigate("/contact");
  };

  return (
    <div>
      <section className="hero">
        <div className="hero-content">
          <h1>Summarize information in seconds!</h1>
          <p className="hero-p-first">
            attoli allows you effortlessly leverage AI at your finger tips to
            summarize information whether it is text or code. Be able to
            summarize pages of information or complex functions within a matter
            of seconds. Utilize the time saved to work more efficiently or
            create that little break to catch your breath. <br />
            <br />
            Come explore with us and see how you can get attoli today!
          </p>
          {!user && (
            <button className="cta-button" onClick={handleGetStarted}>
              Get Started
            </button>
          )}
          {!user && (
            <p className="hero-p-second">
              Be one of our early attoli adopters today!
            </p>
          )}
          {user && (
            <button className="cta-button" onClick={handleSummTool}>
              Try Our Sumtool
            </button>
          )}
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="Attoli example" className="hero-img" />
        </div>
      </section>
      <section className="feature">
        <div className="feature-container">
          <div className="cardContainer">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
      <section className="available">
        <div className="available-container">
          <p>That's right we got a browser extension!</p>
          <a
            href="https://chromewebstore.google.com/detail/attoli/lndohbpmlohohecgflbkipepleefjila?authuser=0&hl=en"
            rel="noreferrer"
            target="_blank"
          >
            <img src={chrome} alt="Link to attoli Chrome Extension" />
          </a>
        </div>
      </section>
      <section className="about" ref={aboutRef}>
        <div className="about-image">
          <img src={about} alt="Attoli example" className="hero-img" />
        </div>
        <div className="about-content">
          <h1>About Us</h1>
          <p className="about-p-first">
            We are two friends (Mac and Sean) who cofound attoli because we
            wanted to break our usual routine. We had the goal of: <br />
            <br />
            1. Learn a new skill, while also taking our minds off of our 9-5.{" "}
            <br />
            2. Make our 9-5 work lives more efficient by increasing the
            knowledge we can consume. <br />
            3. Be able to share the benefits of our learning to others. <br />
            <br />
            Outside of our 9-5 and working on attoli, we are just regular folks;
            we like to hang out with friends/family & pets, we love good food,
            and casually browsing cars for sale that we cannot afford.
          </p>
        </div>
      </section>
      <section className="product" ref={aboutRef}>
        <div className="prod-tiers">
          {tiers.map((tier, index) => (
            <TierCard key={index} {...tier} onButtonClick={handleButtonClick} />
          ))}
        </div>
      </section>
      <section className="contact">
        <div className="contact-container">
          <p>Have a question, comment, or new feature request? </p>
          <button className="contact-button" onClick={handleContact}>
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
