import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Download,
  Pin,
  Pencil,
  Clock,
  CheckCircle,
} from "lucide-react";
import "./howtopagestyle.css";

const GUIDE_SECTIONS = [
  {
    id: 1,
    title: "Download Chrome Extension",
    icon: Download,
    steps: [
      "Open the Chrome Web Store",
      "Search for Attoli",
      'Click "Add to Chrome"',
      "Confirm the installation when prompted",
    ],
  },
  {
    id: 2,
    title: "Pin Extension",
    icon: Pin,
    steps: [
      'Click the Chrome extension button on the top right (or search for Extensions under "Settings")',
      'Select the "Pin" button so Attoli extension is present at all times.',
      "Refresh the browser or tab to see if our extension is pinned.",
    ],
  },
  {
    id: 3,
    title: "Test it out!",
    icon: Pencil,
    steps: [
      "Click on Attoli extension",
      "Login with your credentials",
      "Highlight some text and select sumamrize text!",
      "Or summarize the entire page at once!",
      "Check out the response 😄",
    ],
  },
  {
    id: 4,
    title: "Troubleshooting",
    icon: Clock,
    steps: [
      "Logout and back in",
      "Disable and re-enable the extension",
      "Clear extension cache if needed",
      "Contact support if issues persist",
    ],
  },
];

export default function HowToGuide() {
  const [openSection, setOpenSection] = useState(null);
  const [completedSteps, setCompletedSteps] = useState({});

  const toggleStep = (sectionId, stepIndex) => {
    setCompletedSteps((prev) => {
      const sectionSteps = prev[sectionId] || {};
      return {
        ...prev,
        [sectionId]: {
          ...sectionSteps,
          [stepIndex]: !sectionSteps[stepIndex],
        },
      };
    });
  };

  return (
    <div className="guide-container">
      <div className="guide-card">
        <div className="guide-header">
          <h1 className="guide-title">How to Use Chrome Extension</h1>
          <p className="guide-subtitle">
            Follow these steps to get started with the Chrome extension
          </p>
        </div>

        <div className="guide-sections">
          {GUIDE_SECTIONS.map((section) => {
            const IconComponent = section.icon;

            return (
              <div key={section.id} className="section">
                <button
                  className="section-button"
                  onClick={() =>
                    setOpenSection(
                      openSection === section.id ? null : section.id
                    )
                  }
                >
                  <div className="section-header">
                    <div className="section-icon-container">
                      <IconComponent size={20} />
                    </div>
                    <span className="section-title">{section.title}</span>
                  </div>
                  {openSection === section.id ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </button>

                {openSection === section.id && (
                  <div className="section-content">
                    <ol className="steps-list">
                      {section.steps.map((step, index) => (
                        <li
                          key={index}
                          className="step-item"
                          onClick={() => toggleStep(section.id, index)}
                        >
                          <CheckCircle
                            className={`step-icon ${
                              completedSteps[section.id]?.[index]
                                ? "completed"
                                : "uncompleted"
                            }`}
                            size={24}
                          />
                          <span
                            className={`step-text ${
                              completedSteps[section.id]?.[index]
                                ? "step-completed"
                                : ""
                            }`}
                          >
                            {step}
                          </span>
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
