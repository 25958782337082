import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [formValid, setFormValid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (subject && comments) {
      window.location.href = `mailto:Attoli@outlook.com?subject=${subject}&body=${comments}`;
    }
  };

  const handleValidation = () => {
    setFormValid(subject && comments);
  };

  return (
    <div className="contact-page-container">
      <h1 className="contact-header">Contact Us</h1>
      <span className="contact-email">
        Feel free to reach out to us via email at Attoli@outlook.com
      </span>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Subject:</label>
          <select
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            onKeyUp={handleValidation}
            required
          >
            <option value="" className="none">
              --None--
            </option>
            <option value="New Feature Request">New Feature Request</option>
            <option value="Bug Issue Report">Bug Issue Report</option>
            <option value="Questions, Comments or Concerns">
              Questions, Comments or Concerns
            </option>
          </select>
        </div>
        <div className="form-group">
          <label>Comments:</label>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            onKeyUp={handleValidation}
            required
            placeholder="Enter your comments"
          />
        </div>
        <button type="submit" disabled={!formValid} className="submit-btn">
          Send Email
        </button>
      </form>
    </div>
  );
};

export default Contact;
