import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "lucide-react";
import { onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { auth } from "../firebase";
import "./Plans.css";

const stripePromise = loadStripe(
  "pk_live_51ILbUgCttFFMBVz8PkBApeS0gaaxMC8W2ChIltgsUMECHzeKJ5V7DbGTNrAhbHNgFmIYmhDbn1iY6L38SdaBr6l200sJ8iXGwo"
);

const PlanCard = ({
  title,
  price,
  features,
  priceId,
  onCheckout,
  isLoading,
}) => (
  <div className="plan-card">
    <h2 className="plan-title">{title}</h2>
    <p className="plan-price">${price} USD/month</p>
    <ul className="plan-features">
      {features.map((feature, index) => (
        <li key={index} className="feature-item">
          <svg
            className="feature-icon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={() => onCheckout(priceId)}
      className="subscribe-button"
      disabled={isLoading}
    >
      {isLoading ? <Loader className="button-spinner" /> : "Subscribe"}
    </button>
  </div>
);

export default function Plans() {
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (currentUser.emailVerified) {
          setUser(currentUser);
          setIsVerified(currentUser.emailVerified);
          setLoading(false);
        } else {
          navigate("/verify");
        }
      } else {
        setUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleCheckout = async (priceId) => {
    if (!user) {
      navigate("/login", { state: { from: location } });
      return;
    }

    setCheckoutLoading(true);
    const stripe = await stripePromise;
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(
      functions,
      "createCheckoutSession"
    );

    try {
      const response = await createCheckoutSession({ priceId });
      const sessionId = response.data.id;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Error redirecting to checkout:", error);
        setCheckoutLoading(false);
      }
    } catch (error) {
      console.error("Error handling checkout:", error);
      setCheckoutLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="plans-container">
      <h1 className="plans-title">Choose Your Plan</h1>
      <div className="plans-grid">
        <PlanCard
          title="Member"
          price={5}
          features={[
            "6,000 highlighted words per day",
            "50 URL summaries per day",
            "Basic support",
          ]}
          priceId="price_1QDDuOCttFFMBVz8M64kNSJN"
          onCheckout={handleCheckout}
          isLoading={checkoutLoading}
        />
        <PlanCard
          title="Superuser"
          price={15}
          features={[
            "Unlimited highlighted words",
            "Unlimited URL summaries",
            "Priority support",
          ]}
          priceId="price_1QDDucCttFFMBVz8nSYXUvw4"
          onCheckout={handleCheckout}
          isLoading={checkoutLoading}
        />
      </div>
    </div>
  );
}
