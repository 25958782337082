import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import "./SignUp.css";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [alertVisible, setAlertVisible] = useState(true);
  const [alertOpacity, setAlertOpacity] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          navigate("/sum-tool");
        } else {
          if (successMessage != null) {
            setError("Please verify your email before proceeding.");
            setAlertVisible(true);
            setAlertOpacity(1);
          } else {
            setAlertVisible(false);
          }
        }
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (password.match(/(?=.*[0-9])/)) strength += 1;
    if (password.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/)) strength += 1;
    if (password.match(/(?=.*[a-z])/) && password.match(/(?=.*[A-Z])/))
      strength += 1;
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setError("Please accept the Terms of Service and Privacy Policy to continue.");
      setAlertVisible(true);
      setAlertOpacity(1);
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setAlertVisible(true);
      setAlertOpacity(1);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await sendEmailVerification(user);

      setSuccessMessage("Verification email sent. Please check your inbox.");
      setAlertVisible(true);
      setAlertOpacity(1);
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setError(
            "This email is already in use. Please use a different email or try logging in."
          );
          break;
        case "auth/invalid-email":
          setError(
            "Invalid email address. Please check your email and try again."
          );
          break;
        case "auth/operation-not-allowed":
          setError("Error during sign up. Please contact support.");
          break;
        case "auth/weak-password":
          setError("Password is too weak. Please choose a stronger password.");
          break;
        default:
          setError("An error occurred during sign up. Please try again later.");
      }
      setAlertVisible(true);
      setAlertOpacity(1);
    }
  };

  const handleAlertClick = () => {
    setAlertOpacity(0);
    setTimeout(() => {
      setAlertVisible(false);
      setError(null);
      setSuccessMessage(null);
    }, 300);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="signup-container">
      {alertVisible && error && (
        <div
          className="alert alert-error"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {error}
        </div>
      )}
      {alertVisible && successMessage && (
        <div
          className="alert alert-info"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {successMessage}
        </div>
      )}
      <div className="signup-box">
        <h2 className="signup-title">Create Account</h2>
        <form className="signup-form" onSubmit={handleSignUp}>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                className="form-input"
                value={password}
                onChange={handlePasswordChange}
                required
                placeholder="Password"
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? (
                  <EyeOff className="password-toggle-icon" />
                ) : (
                  <Eye className="password-toggle-icon" />
                )}
              </button>
            </div>
            <div
              className="password-strength-group"
              data-strength={passwordStrength}
            >
              <div className="password-strength-meter">
                <div className="meter-block"></div>
                <div className="meter-block"></div>
                <div className="meter-block"></div>
                <div className="meter-block"></div>
              </div>
              <div className="password-strength-message">
                <div className="message-item">Weak Password</div>
                <div className="message-item">Okay</div>
                <div className="message-item">Strong</div>
                <div className="message-item">Very Strong!</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="password-input-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                className="form-input"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm Password"
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
              >
                {showConfirmPassword ? (
                  <EyeOff className="password-toggle-icon" />
                ) : (
                  <Eye className="password-toggle-icon" />
                )}
              </button>
            </div>
          </div>
          <div className="form-group terms-checkbox">
            <div className="terms-text-container">
              I agree to the{" "}
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>
            </div>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <span className="checkmark"></span>
              <span className="checkbox-label">Yes, I agree</span>
            </label>
          </div>
          <button type="submit" className="signup-button">
            Sign Up
          </button>
        </form>
        <div className="login-link">
          Already have an account? <Link to="/login">Log in</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;