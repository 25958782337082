import React, { useEffect } from "react";
import "./Privacystyles.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      id: "collection",
      title: "1. What information do we collect?",
      content: `Personal information you disclose to us

In Short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
- names
- email addresses
- usernames
- passwords
- phone numbers
- billing addresses
- contact preferences
- debit/credit card numbers

Sensitive Information. We do not process sensitive information.

Payment Data. We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Stripe.

Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account.

Information automatically collected

In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.

We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.`,
    },
    {
      id: "processing",
      title: "2. How do we process your information?",
      content: `In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.

We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
- To facilitate account creation and authentication and otherwise manage user accounts
- To deliver and facilitate delivery of services to the user
- To respond to user inquiries/offer support to users
- To fulfill and manage your orders
- To request feedback
- To send you marketing and promotional communications
- To protect our Services
- To evaluate and improve our Services, products, marketing, and your experience
- To identify usage trends
- To determine the effectiveness of our marketing and promotional campaigns
- To comply with our legal obligations`,
    },

    {
      id: "legal-bases",
      title:
        "3. What legal bases do we rely on to process you personal information?",
      content: `In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason.

If you are located in Canada, this section applies to you.

We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.

In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
- If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
- For investigations and fraud detection and prevention
- For business transactions provided certain conditions are met
- If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
- If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
- If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records`,
    },
    {
      id: "sharing",
      title: "4. When and with whom do we share your personal information?",
      content: `In Short: We may share information in specific situations described in this section and/or with the following third parties.

We may need to share your personal information in the following situations:
- Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.`,
    },
    {
      id: "cookies",
      title: "5. Do we use cookies and other tracking technologies?",
      content: `In Short: We may use cookies and other tracking technologies to collect and store your information.

We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.`,
    },
    {
      id: "ai",
      title: "6. Do we offer Artificial Intelligence-based Products?",
      content: `In Short: We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.

As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions.

Use of AI Technologies

We provide the AI Products through third-party service providers ("AI Service Providers"), including OpenAI. Your input, output, and personal information will be shared with and processed by these AI Service Providers to enable your use of our AI Products.`,
    },
    {
      id: "social-logins",
      title: "7. How do we handle your social logins?",
      content: `In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.

Our Services offer you the ability to register and log in using your third-party social media account details. Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.`,
    },
    {
      id: "retention",
      title: "8. How long do we keep your information?",
      content: `In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.

We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law. No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.`,
    },
    {
      id: "security",
      title: "9. How do we keep your information safe?",
      content: `In Short: We aim to protect your personal information through a system of organizational and technical security measures.

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure.`,
    },
    {
      id: "minors",
      title: "10. Do we collect information from minors?",
      content: `In Short: We do not knowingly collect data from or market to children under 18 years of age.

We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services.`,
    },
    {
      id: "privacy-rights",
      title: "11. What are your Privacy Rights?",
      content: `In Short: Depending on your location, you may have certain rights regarding your personal information.

In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right to:
- Request access and obtain a copy of your personal information
- Request rectification or erasure
- Restrict the processing of your personal information
- Data portability
- Object to the processing of your personal information

You can make such a request by contacting us using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.`,
    },
    {
      id: "dnt",
      title: "12. Controls for do-not-track features?",
      content: `Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.`,
    },
    {
      id: "us-privacy",
      title: "13. Do United States residents have specific Privacy Rights?",
      content: `In Short: If you are a resident of certain US states, you may be granted specific rights regarding access to your personal information.

We have collected the following categories of personal information in the past twelve (12) months:
- Identifiers (e.g., name, email address)
- Personal information as defined in the California Customer Records statute
- Geolocation data

Your Rights
You may have the right to:
- Know whether we are processing your personal data
- Access your personal data
- Request correction of your personal data
- Request deletion of your personal data
- Obtain a copy of your personal data
- Opt out of the processing of your personal data for targeted advertising`,
    },

    {
      id: "EEA",
      title: "14. Do European residents have specific Privacy Rights?",
      content: `EEA Residents

"Our Service and related data processing activities are intended solely for individuals residing in North America. We do not target or knowingly collect personal information from individuals within the European Economic Area (EEA). If you are located in the EEA, please do not provide us with any personal information, and refrain from using our Service.

In the event that we inadvertently collect personal data from EEA residents, we will delete such data upon request. Please contact Attoli@outlook.com if you are an EEA resident and believe we have inadvertently collected your data."

Data Deletion Requests for Unauthorized Users

"If we identify that a user is accessing our Service from the EEA or another region with specific data protection laws, we may require that user to terminate their use of the Service. Any unauthorized users identified as EEA residents may request that their personal data be deleted by contacting Attoli@outlook.com."`,
    },

    {
      id: "updates",
      title: "15. Do we make updates to this Notice?",
      content: `In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.`,
    },
    {
      id: "contact",
      title: "16. How can you contact us about this Notice?",
      content: `If you have questions or comments about this notice, you may email us at Attoli@outlook.com.`,
    },

    {
      id: "review",
      title:
        "17. How can you review, update, or delete data we collect from you?",
      content: `Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.`,
    },
  ];

  return (
    <div className="pl-page-wrapper">
      <h1>PRIVACY POLICY</h1>
      <p>Last updated October 07, 2024</p>

      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <nav>
          <ul>
            {sections.map((section) => (
              <li key={section.id}>
                <a href={`#${section.id}`}>{section.title}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div>
        {sections.map((section) => (
          <section key={section.id} id={section.id}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </section>
        ))}
      </div>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these terms, please contact us at
          Attoli@outlook.com.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
