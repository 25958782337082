import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoginPage.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alertVisible, setAlertVisible] = useState(true);
  const [alertOpacity, setAlertOpacity] = useState(1);
  const [successMessage, setSuccessMessage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { from } = location.state || { from: { pathname: "/sum-tool" } };
        navigate(from.pathname);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  const handleEmailPasswordLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const { from } = location.state || { from: { pathname: "/sum-tool" } };
      navigate(from.pathname);
    } catch (error) {
      console.error("Login error:", error);
      switch (error.code) {
        case "auth/invalid-email":
          setError("Invalid email address. Please check email and try again.");
          break;
        case "auth/user-disabled":
          setError("This account has been disabled. Please contact support.");
          break;
        case "auth/too-many-requests":
          setError("To many login requests. Please try again later.");
          break;
        case "auth/user-not-found":
        case "auth/wrong-password":
        case "auth/invalid-login-credentials":
          setError("Incorrect email or password. Please try again.");
          break;
        default:
          setError("An error occurred. Please try again later.");
      }
      setAlertVisible(true);
      setAlertOpacity(1);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      const { from } = location.state || { from: { pathname: "/sum-tool" } };
      navigate(from.pathname);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        setSuccessMessage(
          "Password reset email sent! If you don't see it please check your spam folder."
        );
        showAlert();
      } catch (error) {
        console.error("Error sending password reset email:", error);
        setError("Failed to send password reset email.");
        showAlert();
      }
    } else {
      setError("Please enter your email address first.");
      showAlert();
    }
  };

  const showAlert = () => {
    setAlertVisible(true);
    setAlertOpacity(1);
  };

  const handleAlertClick = () => {
    setAlertOpacity(0);
    setTimeout(() => {
      setAlertVisible(false);
      setError(null);
      setSuccessMessage(null);
    }, 300);
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  return (
    <div className="login-container">
      {alertVisible && error && (
        <div
          className="alert alert-error"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {error}
        </div>
      )}
      {alertVisible && successMessage && (
        <div
          className="alert alert-info"
          onClick={handleAlertClick}
          style={{
            opacity: alertOpacity,
            transition: "opacity 300ms ease-in-out",
          }}
        >
          {successMessage}
        </div>
      )}
      <div className="login-box">
        <h2 className="login-title">Welcome Back!</h2>
        <form className="login-form" onSubmit={handleEmailPasswordLogin}>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        <button onClick={handleGoogleLogin} className="google-button">
          <svg
            className="chrome-logo"
            enable-background="new 0 0 512 512"
            height="20"
            viewBox="0 0 512 512"
            width="20"
          >
            <path
              d="m512 256c0-16.084-1.509-32.183-4.497-47.813-1.348-7.075-7.544-12.188-14.736-12.188h-235.767c-8.291 0-15 6.709-15 15v90c0 8.291 6.709 15 15 15h120.967c-7.537 15.258-17.924 28.497-30.121 39.635l84.78 84.78c48.781-46.475 79.374-111.875 79.374-184.414z"
              fill="#ffffff"
            />
            <path
              d="m256 120c29.429 0 57.422 9.346 80.947 27.041 5.947 4.468 14.341 3.926 19.614-1.392l64.014-64.014c2.974-2.974 4.57-7.061 4.38-11.265s-2.124-8.13-5.347-10.84c-45.805-38.392-103.916-59.53-163.608-59.53-87.849 0-165.458 44.654-211.337 112.452l88.048 88.048c21.006-47.316 68.268-80.5 123.289-80.5z"
              fill="#ffffff"
            />
            <path
              d="m256 512c57.263 0 109.993-19.2 152.602-51.187l-86.371-86.371c-19.742 11.116-42.306 17.558-66.231 17.558-55.021 0-102.283-33.184-123.289-80.5l-88.048 88.048c45.879 67.798 123.488 112.452 211.337 112.452z"
              fill="#ffffff"
            />
            <path
              d="m122.184 277.605c-1.149-7.079-2.184-14.208-2.184-21.605s1.035-14.526 2.184-21.605l-94.124-94.124c-17.808 34.78-28.06 74.045-28.06 115.729s10.252 80.949 28.061 115.728z"
              fill="#ffffff"
            />
          </svg>
          <span>Login/ Sign-up with Google</span>
        </button>
        <button
          type="button"
          className="forgot-password-button"
          onClick={handleForgotPassword}
        >
          Forgot Password?
        </button>
        <div className="signup-link">
          Don't have an account? <a href="/sign-up">Sign Up</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
