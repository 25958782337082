import React, { useState } from "react";
import "./FAQPage.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <button className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className="faq-icon">{isOpen ? "−" : "+"}</span>
      </button>
      <div className="faq-answer">
        <p>{answer}</p>
      </div>
    </div>
  );
};

const FAQPage = () => {
  const faqData = [
    {
      question: "What is Attoli?",
      answer:
        "Attoli is an AI-powered text summarization utility that is geared towards helping you accurately and efficiently consume large sums of information.",
    },
    {
      question: "How does Attoli work?",
      answer:
        "Attoli uses advanced natural language processing algorithms to analyze text while understanding the nuances of language to extract the core essence of information submitted.",
    },
    {
      question: "Is Attoli free to use?",
      answer:
        "We offer both free and membership plans. The free plan offers a reduced usage bundle while still offering the same level of accuracy, while paid plans offer elevated usage and support.",
    },
    {
      question: "Can Attoli summarize any type of text?",
      answer:
        "With our prompting, Attoli can summarize a wide variety of text types, including articles, research papers, news reports, and more.",
    },
    {
      question: "Is my data safe with Attoli?",
      answer:
        "Yes, we take data privacy and security very seriously. All data is encrypted, and we never share your information with third-parties.",
    },
  ];
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FAQPage;
