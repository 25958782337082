import React, { useState, useEffect } from "react";
import { Share2, MessageCircle, Users } from "lucide-react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Success.css";

const planDetails = {
  Member: {
    price: 5,
    features: [
      "6,000 highlighted words per day",
      "50 URL summaries per day",
      "Basic support",
    ],
  },
  Superuser: {
    price: 15,
    features: [
      "Unlimited highlighted words",
      "Unlimited URL summaries",
      "Priority support",
    ],
  },
};

const handleShare = async () => {
  const shareData = {
    title: "Join me on Attoli!",
    text: `I've just joined Attoli's and it's awesome! Check it out!`,
    url: "https://www.attoli.com",
  };

  if (navigator.share) {
    try {
      await navigator.share(shareData);
      console.log("Shared successfully");
    } catch (err) {
      console.log("Error sharing:", err);
    }
  } else {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareData.text
    )}&url=${encodeURIComponent(shareData.url)}`;
    window.open(shareUrl, "_blank");
  }
};

const Success = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [plan, setPlan] = useState("Member");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setPlan("Member");
      } else {
        navigate("/login");
      }
    });

    setIsVisible(true);

    return () => unsubscribe();
  }, [navigate]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`success-container ${isVisible ? "fade-in" : ""}`}>
      <div className="success-card">
        <div className="success-icon animate-bounce">🎉</div>
        <h1 className="success-title">
          Welcome to the attoli family, {user.displayName || user.email}!
        </h1>
        <p className="success-message">
          Your support helps us improve and maintain our service. Your account
          has been successfully upgraded, and you're all set to enjoy your new
          benefits!
        </p>
        <div className="social-proof">
          <Users size={24} />
          <p>
            You've joined our growing family of satisfied Attoli users
            worldwide!
          </p>
        </div>
        <div className="button-group">
          <button
            className="success-button primary"
            onClick={() => (window.location.href = "/sum-tool")}
          >
            Go to Sumtool
          </button>
          <button
            className="success-button secondary"
            onClick={() => (window.location.href = "/contact")}
          >
            <MessageCircle size={16} />
            Need Help?
          </button>
          <button className="success-button secondary" onClick={handleShare}>
            <Share2 size={16} />
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
